html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

main {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

a {
  background-color: #0000;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  border-style: none;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  max-width: 100%;
  white-space: normal;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template, [hidden] {
  display: none;
}

document, body {
  overflow: hidden;
}

canvas {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
}

#bottombar, #topbar {
  z-index: 20;
  width: 100%;
  background: #00f;
  position: fixed;
  left: 0;
}

#topbar {
  top: 0;
}

#menu {
  width: 100%;
  height: 4em;
}

#menu > ul {
  height: 100%;
  justify-content: flex-start;
  align-items: stretch;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

#menu > ul > li {
  box-sizing: border-box;
  border-left: 2px solid #000;
}

#menu > ul > li > button, #menu > ul > li > button > img {
  width: 100%;
  height: 100%;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
}

#menu > ul > li > button {
  cursor: pointer;
  background: #ff0;
  padding-left: 10px;
  padding-right: 10px;
}

#menu > ul > li > button:hover {
  opacity: .8;
}

#menu > ul > li > button:active, #menu > ul > li > button.pressed {
  background: purple;
}

#dropdowns > * {
  width: 100%;
  border-bottom: 1px solid #000;
  padding-top: 5px;
  padding-bottom: 5px;
  display: block;
}

#dropdowns > :nth-child(2n) {
  background: #00a;
}

#dropdowns > .hidden {
  display: none !important;
}

#dropdowns > .dropdown {
  width: 100%;
  display: flex;
}

#dropdowns > .dropdown > input {
  flex-grow: 1;
}

#pin_dropdown {
  place-content: stretch;
  display: flex;
}

#pin_dropdown > input#pin_colour {
  min-width: 50px;
  padding: 0;
}

#pin_dropdown > input#pin_text, #pin_dropdown > input#pin_size_slider {
  flex-grow: 1;
}

#bottombar {
  height: 2em;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;
  display: flex;
  bottom: 0;
}

#bottombar button {
  cursor: pointer;
  min-width: 42px;
  background: #ff0;
  border: none;
  margin: 0;
  padding-left: 5px;
  padding-right: 5px;
}

#bottombar button > img {
  width: 100%;
  height: 100%;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
}

#bottombar #browser {
  flex-grow: 1;
}

#pause_img {
  display: none;
}

#timestamp_container {
  align-items: center;
  display: flex;
}

#timestamp_container > span {
  padding: 10px;
}

.pin {
  cursor: pointer;
  border: none;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.pin:hover {
  background: #fff !important;
}

.pin:focus, .pin:active {
  border: 2px solid #fff;
}

@media (orientation: landscape) {
  #topbar, #bottombar {
    width: 33%;
    font-size: 1.5em;
  }

  #pin_dropdown > input#pin_text, #pin_dropdown > input#pin_size_slider {
    width: 33%;
  }
}

/*# sourceMappingURL=index.739f155d.css.map */
