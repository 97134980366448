@import "normalise.css";

document,
body {
	overflow: hidden;
}

canvas {
	position: absolute;
	left: 0;
	top: 0;
	z-index: -1;
}

#bottombar,
#topbar {
    z-index: 20;
    position: fixed;
    left: 0;
    width: 100%;
    background: blue;
}

#topbar {
    top: 0;
}

#menu {
    width: 100%;
    height: 4em;
}

#menu > ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    height: 100%;
}

#menu > ul > li {
    border-left: 2px solid black;
    box-sizing: border-box;
}

#menu > ul > li > button,
#menu > ul > li > button > img {
    padding: 0;
    margin: 0;
    border: none;
    background: none;
    width: 100%;
    height: 100%;
}

#menu > ul > li > button {
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
    background: yellow;
}

#menu > ul > li > button:hover {
    opacity: 0.8;
}

#menu > ul > li > button:active,
#menu > ul > li > button.pressed {
    background: purple;
}

#dropdowns > * {
    display: block;
    width: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid black;
}

#dropdowns > *:nth-child(even) {
    background: #0000aa;
}

#dropdowns > .hidden {
    display: none!important;
}

#dropdowns > *.dropdown  {
    display: flex;
    width: 100%;
}
#dropdowns > *.dropdown  > input {
    flex-grow: 1;
}

#pin_dropdown {
    align-content: stretch;
    display: flex;
    justify-content: stretch;
}

#pin_dropdown > input#pin_colour {
    min-width: 50px;
    padding: 0;
}

#pin_dropdown > input#pin_text,
#pin_dropdown > input#pin_size_slider {
    flex-grow: 1;
}

#bottombar {
    bottom: 0;
    height: 2em;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    align-items: stretch;
}

#bottombar button {
    cursor: pointer;
    min-width: 42px;
    padding-left: 5px;
    padding-right: 5px;
    margin: 0;
    border: none;
    background: yellow;
}

#bottombar button > img {
    padding: 0;
    margin: 0;
    border: none;
    background: none;
    width: 100%;
    height: 100%;
}

#bottombar #browser {
    flex-grow: 1;
}

#pause_img {
    display: none;
}

#timestamp_container {
    display: flex;
    align-items: center;
}

#timestamp_container > span {
    padding: 10px;
}

.pin {
    cursor: pointer;
    display: flex;
    align-items: center;
    overflow: hidden;
    border: none;
}

.pin:hover {
    background: white!important;
}

.pin:focus,
.pin:active {
    border: 2px solid white;
}

@media (orientation:landscape) {
    #topbar,
    #bottombar {
        width: 33%;
        font-size: 1.5em;
    }
    #pin_dropdown > input#pin_text,
    #pin_dropdown > input#pin_size_slider {
        width: 33%;
    }
}
